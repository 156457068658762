import React, { useState, useEffect } from "react";
import { Button, Img } from "../../Component";
import {logEvent} from "../../Utils/Analytics";
import { EVENT_CATEGORIES, EVENT_ACTIONS, EVENT_LABELS } from "../../Constants/myDharmaContent";
export default function FloatingRegisterButton({ setIsNotifyModalOpen }) {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleRegisterClick = () => {
    logEvent(EVENT_CATEGORIES.MY_DHARMA,EVENT_ACTIONS.CLICK,EVENT_LABELS.MY_DHARMA_REGISTER);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="tw-fixed tw-bottom-10 tw-right-4 tw-z-50">
      <div className="tw-flex tw-items-center tw-gap-2">
        <span
          className={`${
            isScrolled ? "sm:tw-hidden" : ""
          } tw-hidden sm:tw-flex tw-items-center tw-bg-[#ffffff] tw-rounded-lg tw-shadow-md tw-px-3 tw-py-2 tw-text-[11px] tw-font-playfairdisplay tw-text-[#5A1740] tw-border tw-border-[#CFCFCF]`}
        >
          Notify me when My Dharma App is LIVE
        </span>
        <Button
          shape="square"
          variant="fill"
          className="tw-flex tw-items-center tw-gap-2 tw-rounded-lg tw-shadow-md tw-bg-[#5A1740] hover:tw-bg-[#761E54]"
          onClick={() => {setIsNotifyModalOpen(true);handleRegisterClick()}} //changed the function to handle the button click
        >
          <Img
            src="/assets/images/register.webp"
            alt="Register-Icon" 
            className="tw-h-6 tw-w-6"
            loading="lazy"
          />
          <span className="sm:tw-hidden">Register</span>
        </Button>
      </div>
    </div>
  );
}
