import ReactGA from "react-ga4";

/**
 * Initializes Google Analytics with the provided tracking ID.
 */
export const initGA = () => {
  ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_ID}`);
};

/**
 * Logs a page view to Google Analytics.
 * 
 * @param {string} [title=''] - The title of the page. Optional.
 */
export const logPageView = (title = '') => {
  const pageViewData = { hitType: 'pageview', page: window.location.pathname };
  if (title) {
    pageViewData.title = title;
  }
  ReactGA.send(pageViewData);
};

/**
 * Logs an event to Google Analytics.
 * 
 * @param {string} category - The category of the event.
 * @param {string} action - The action of the event.
 * @param {string} [label=''] - The label of the event. Optional.
 */
export const logEvent = (category = "", action = "", label = "") => {
  if (category && action) {
    ReactGA.event({ category, action, label });
  }
};

/**
 * Logs a conversion event to Google Analytics.
 * 
 * @param {string} category - The category of the event.
 * @param {string} action - The action of the event.
 * @param {string} label - The label of the event.
 */
export const logConversion = (category = "", action = "", label = "") => {
  if (category && action && label) {
    ReactGA.event({
      category,
      action,
      label,
    });
  }
};