import React from "react";
import { Title, Img } from "..";

export default function PujaDetail({
  pujas,
  defaultImage = `${process.env.REACT_APP_IMAGE_URL_317_166}/default-no-puja-image.webp`,
  goldLineImage = "assets/images/gold-line.webp",
  bookNowButtonText = "Know More",
  ...props
}) {

  const handlePujaClick = () => {
    localStorage.setItem("pujaId", pujas.pujas?.id);
    let navigateTo = "poojas/telugu/" + pujas?.pujas?.url_name;
    window.open(navigateTo, "_self");
  };

  return (
    <>
    <div
      {...props}
      className={`${props.className} tw-flex tw-flex-col tw-items-center md:tw-w-full tw-gap-3 tw-px-7 tw-py-[30px] sm:tw-p-5 tw-border-[0.5px] tw-border-solid tw-bg-white-a700 tw-shadow-xs tw-cursor-pointer`}    //
      onClick={handlePujaClick}
        >
      <Img
        src={`${process.env.REACT_APP_IMAGE_URL_317_166}/${pujas?.pujas?.url_name?.trim()}.webp`}
        loading="lazy"
        alt={`${pujas?.pujas?.name}-Image`}
        className="tw-h-[167px] tw-object-cover tw-w-[317px] md:tw-w-[243px] sm:tw-w-[280px]"
        style={{ border: "0.5px solid #5a1740" }}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = defaultImage;
        }}
      />
      <div className="tw-flex tw-flex-col tw-gap-4 tw-self-stretch">
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-1">
          <Title size="textlg" as="p" className="tw-text-[24px] tw-font-medium tw-text-gray-900 sm:tw-text-[20px]">{/*display the puja name */}
        {pujas?.pujas?.name}
          </Title>
          <Title
        size="texts"
        as="p"
        className="tw-w-full tw-font-quicksand tw-text-[14px] tw-font-medium tw-leading-6 tw-tracking-[0.14px] tw-text-gray-900 tw-overflow-hidden tw-line-clamp-3 tw-h-[72px]"
          >
        {pujas?.pujas?.description}
          </Title>
        </div>
        <Img
          src={goldLineImage}
          alt="Gold-Line"
          className="tw-h-[10px] tw-object-cover"
          loading="lazy"
        />
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-2.5">
          <div className="tw-flex tw-items-center tw-self-stretch">
            <div className="tw-flex tw-flex-1 tw-flex-col tw-items-start">
              <div className="tw-flex tw-items-start tw-gap-2 tw-self-stretch">
              </div>
            </div>
            <button
              shape="square"
              className="tw-min-w-[122px] !tw-border-[#000] tw-border-[1px] tw-font-quicksand tw-font-medium tw-tracking-[0.16px] sm:tw-px-5 tw-bg-[#ffffff] tw-px-[23px] tw-py-[10px] tw-text-[16px] hover:tw-border-[#761E54] hover:tw-bg-[#FFFAFD] tw-text-[black]"
            >
              {bookNowButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
