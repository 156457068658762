import React, { useState, useEffect } from "react";
import "./PrivacyPolicy.scss";
import Footer from "../../Component/Footer/Footer";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import Header from "../../Component/Header";
import Loader from "../../Component/Loader/Loader";
export default function PrivacyPolicy() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    // Check if document is already loaded
    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
    }

    // Cleanup
    return () => window.removeEventListener('load', handleLoad);
  }, []);
  return (
    <>
     {isLoading && <Loader fullScreen />}
      <Header /> 
      <div className="policySection">
        <div className="ppfstsec">
          <div className="centertext herosection-text">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h1>Privacy Policy</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ppsndsec">
          <div className="container">
            <p>
              <strong>THIS PRIVACY POLICY (“Privacy Policy”)</strong>{" "}
              incorporated herein to make you aware with the guidelines or steps
              used by Company to protect your information shared by you or
              collected by the Company.
            </p>
            <p>
              <strong>KARISHYE PRIVATE LIMITED,</strong> a company incorporated
              under the laws of India and having its registered office at H –
              802, Aparna Cyberzon, Nallagandla, Serilingampally, Hyderabad,
              Telangana, 500019 where such expression shall, unless repugnant to
              the context thereof, be deemed to include its representatives,
              affiliates, administrators, permitted successors and assigns 
              <strong>
                (hereinafter referred to as “Company” or “we” or “us” or “our”)
              </strong>
            </p>
            <p>
              The Company may collect or ask or gather certain personal
              identifiable information through your access to and use of the
              <strong> “Karishye.co” </strong> website, mobile app as well as any
              other media form, media channel, mobile website related linked, or
              otherwise connected thereto
              <strong> (collectively, the “Site”) </strong>in order to provide
              <strong> “you” or “your” or “yourself” or “user” </strong>(person
              accessing the Site) our services. The Company will always take
              every possible action to uphold your trust which you placed in the
              Company. Your privacy and the security of your Personal
              Information (defined below) are very important to the Company
              which is why the Company manages your Personal Information with
              the utmost attention and adopts specific measures to keep it
              secure.
            </p>
            <p>
              The creation of this Privacy Policy ensures our steady commitment
              to your privacy with regard to the protection of your invaluable
              information. Our Privacy Policy is subject to change at any time
              without notice. Please read the Privacy policy carefully before
              providing or allowing us to gather or collect your Personal
              information.
            </p>
            <p>
              BY ACCESSING, BROWSING OR OTHERWISE USING THE SITE, YOU EXPRESSLY
              AGREE TO BE BOUND WITH THE TERMS OF THIS PRIVACY POLICY. IF YOU DO
              NOT AGREE WITH THE TERMS MENTIONED HEREIN, PLEASE EXIT THE SITE
              AND RESTRICT YOUR ACCESS TO THE SITE. THIS PRIVACY POLICY IS
              INCORPORATED IN ACCORDANCE AND SUBJECT TO THE TERMS OF USE.
            </p>
            <p>
              <strong> “Personal Information” </strong>shall mean and refer to any
              personally identifiable information that the Company may collect
              from you or which is gathered by the Company.
            </p>
            <p>
              This Privacy Policy describes how the Company may use and disclose
              personally identifiable information that the Company may collect
              about you through the Site
              <strong> (“Personal Information“). </strong>We insist upon the
              highest standards for secure transactions and customer information
              privacy.
            </p>
            <p>
              <strong>COLLECTIONS AND USES OF PERSONAL INFORMATION</strong>
            </p>
            <p>
              We collect certain Personal Information about you which is
              essential to drive our business and to deliver our services. The
              Company will have information about you if (i) You have provided
              the information yourself; or (ii) The Company has automatically
              collected the information via the Site.
            </p>
            <p>
              (i)<u>Information provided by You</u>
            </p>
            <p>
              a) The categories of Personal Information that you provide to us
              during <u>Registration / Signup</u> include but are not limited to
              your name, your contact information (address, email address and
              phone number), your company name, your country, your preferred
              language, your bank details, et al. In the course of the
              registration process, you will be required to choose a username
              and a strong password (eight digits alpha-numeric) to complete the
              registration process. This is to enforce the agreement between you
              and the Company, to ensure that you are reachable by us, or during
              troubleshoot problems, so while providing the above Personal
              Information, please provide us the accurate and correct
              information as they will be used while resetting your password.
            </p>
            <p>
              b) Whenever you submit any request to the Company using Site, we
              may ask you to provide your name, your contact information, your
              address (billing address and delivery address), your account
              details or your bank details or other payment account information.
            </p>
            <p>
              c) Depending on the nature of your inquiry or activities on the
              Site, we may also obtain other Personal Information about your
              interests, likes or dislikes that you specifically describe to us.
            </p>
            <p>
              PROVIDING PERSONAL INFORMATION IS NECESSARY FOR PERFORMANCE OF THE
              SERVICES AND FUNCTIONALITIES OFFERED BY THE SITE. IF YOU DECLINE
              TO PROVIDE THE REQUISITE PERSONAL INFORMATION, WE MAY NOT BE ABLE
              TO PROCESS YOUR INQUIRY OR OTHER REQUESTS.
            </p>
            <p>
              (ii)<u>Information collected by the Company</u>
            </p>
            <p>
              a) When you visit our Site, we may collect information that web
              browsers, mobile devices and servers make available, such as the
              internet protocol address, browser type, language preference, time
              zone, referring URL, date and time of access, operating system,
              mobile device manufacturer and mobile network information. We
              include these in our log files to understand more about visitors.
            </p>
            <p>
              b) COOKIES: The Site also utilizes cookies to track your online
              activities, including your registration, submissions, and
              information requests, in accordance with applicable laws. Cookies
              are files placed on your device by a Site server. Cookies on this
              Site do not collect Personal Information, we do track your online
              activities and may link that information with Personal Information
              you have provided to us. Your web browser may be set to accept
              cookies automatically but can be changed to decline them. The
              ‘Help Section’ of the toolbar on most browsers will tell you how
              to set your browser to prevent accepting new cookies, how to have
              the browser notify you when you receive a new cookie or how to
              disable cookies altogether. Please note that if you decline to
              accept cookies, and if you choose to download materials from the
              Site, you may be required to accept each time you download
              materials from the Site.
            </p>
            <p>
              <strong>USER DATA</strong>
            </p>
            <p>
              The Company will maintain certain information that you transmit to
              the Site for the purpose of managing the performance of the
              services. Although we perform regular routine backups of the
              information, you are solely responsible for all data that you
              transmit or that relates to any activity you have undertaken using
              the Site.
            </p>
            <p>
              YOU AGREE THAT THE COMPANY SHALL HAVE NO LIABILITY TOWARDS YOU FOR
              ANY LOSS OR CORRUPTION OF ANY SUCH DATA, AND YOU HEREBY WAIVE ANY
              RIGHT OF ACTION AGAINST THE COMPANY ARISING FROM ANY SUCH LOSS OR
              CORRUPTION OF SUCH DATA.
            </p>
            <p>
              <strong>PERIOD OF RETENTION OF YOUR PERSONAL INFORMATION</strong>
            </p>
            <p>
              We keep your Personal Information for a limited period of time,
              which depends on the purposes for which it was collected; after
              such period, your Personal Information will be deleted or
              nevertheless rendered irreversibly anonymous. The retention period
              is different depending on the purpose of processing: for example,
              the Personal Information collected during the booking request, is
              processed until all of the administrative and accounting
              formalities have been completed, and is then archived, while the
              Personal Information used to send you our newsletter until you
              request that we stop sending it to you is nevertheless kept for a
              period of no more than 24 months.
            </p>
            <p>
              <strong>OUR USE OF YOUR PERSONAL INFORMATION</strong>
            </p>
            <p>
              Our primary goal in collecting your Personal Information is to
              understand your needs and to enhance your experience on our Site.
              Providing Personal Information is necessary for performance of the
              services and functionalities offered by the Site. The Company may
              also disclose Personal Information about our Users when we
              believe, in good faith, that the disclosure is required by
              applicable laws.
            </p>
            <p>
              <strong>ADVERTISEMENTS / MARKETING</strong>
            </p>
            <p>
              We use Personal Information and other information collected
              through the Site to provide you with the requested services. We
              may also from time to time use your Personal Information to send
              you automated email messages or marketing materials regarding our
              services. These email messages may contain web beacons and other
              features that help us make sure that you received and were able to
              open the message. You may opt out of receiving such marketing
              email messages at any time by sending a message with “unsubscribe”
              in the subject line to{" "}
              <a href="mailto:help@karishye.co">help@karishye.co</a>
            </p>
            <p>
              <strong>DISCLOSURES OF PERSONAL INFORMATION</strong>
            </p>
            <p>
              We may disclose Personal Information to the third parties and to
              our affiliates in your local jurisdiction or other locations as
              necessary or appropriate to provide the product or service you
              requested, to contact you in connection with product or services,
              or for other legitimate business purposes.
            </p>
            <p>
              We may disclose your Personal Information to companies and
              individuals we employ to perform business functions and services
              on our behalf. Such functions may include hosting our web servers,
              analysing data, providing legal, accounting and marketing
              services, and providing other support services. All such parties
              will be under obligation to maintain the security and
              confidentiality of your Personal Information, and to process the
              data in accordance with our instructions.
            </p>
            <p>
              We may disclose Personal Information to the extent necessary or
              appropriate to government agencies, advisors, and other third
              parties in order to comply with applicable laws, or if such action
              is necessary to protect the rights or property of the Company or
              its affiliated companies.
            </p>
            <p>
              <strong>YOUR RIGHTS</strong>
            </p>
            <p>
              At any time, depending on the specific processing, you may revoke
              your consent to processing, find out what Personal Information is
              in our possession, its source and how it is used, ask that the
              data be updated, corrected or supplemented as well as, have it
              deleted, limit its processing, or oppose it. The Company
              undertakes to provide you the following rights no matter where you
              choose to live.
            </p>
            <p>
              a) We will provide access to your Personal Information that we
              hold.
            </p>

            <p>
              b) We will provide to update and amend, and/or delete the Personal
              Information in accordance with applicable laws.
            </p>

            <p>
              c) You can also exercise other rights acknowledged by applicable
              data protection legislation, including, for example, objection to
              and blocking of the processing of your Personal Information, by
              contacting us at{" "}
              <a href="mailto:help@karishye.co">help@karishye.co.</a>
            </p>

            <p>
              d) For security reasons, the Company reserves the right to take
              steps to authenticate the identity of the requesting party before
              providing access to Personal Information in accordance with this
              section of the Privacy Policy.
            </p>
            <p>
              <strong>PERSONAL DATA SECURITY AND CONFIDENTIALITY</strong>
            </p>
            <p>
              We have implemented and will continually maintain appropriate
              electronic, physical, and organizational security procedures,
              measures and controls in order to protect against accidental,
              unauthorized or unlawful access, destruction, use, alteration,
              modification, disclosure or loss of your Personal Information,
              which measures and steps of safeguarding Personal Information will
              be in accordance with industry accepted best practices and
              applicable laws.
            </p>
            <p>
              <strong>EXTERNAL LINKS ON THE SITE</strong>
            </p>
            <p>
              The Site may include hyperlinks to other websites, content or
              resources. We have no control over such external links present in
              the Site, which are provided by persons or companies other than
              the Company.
            </p>
            <p>
              You acknowledge and agree that, we are not responsible for any
              collection or disclosure of your Personal Information by any
              external site, companies or persons, nor do we endorse any
              advertising, products or other material on or available from such
              external site, websites or resources.
            </p>
            <p>
              You further acknowledge and agree that we are not liable for any
              loss or damage which may be incurred by you as a result of the
              collection and/or disclosure of your Personal Information by
              external websites, or resources, or as a result of any reliance
              placed by you on the completeness, accuracy or existence of any
              advertising, products or other materials. This external website
              and resource providers may have their own privacy policies
              governing the collection, storage, retention and disclosure of
              your Personal Information that you may be subject to. We recommend
              that you enter the external website and review their privacy
              policy.
            </p>
            <p>
              <strong>
                DISCLOSURE OF YOUR PERSONAL INFORMATION TO THIRD PARTIES
              </strong>
            </p>
            <p>
              Due to the existing regulatory environment, we cannot ensure that
              all of your Personal Information shall never be disclosed in ways
              other than those described in this Privacy Policy. Although, we
              use industry standard practices to protect your privacy, we do not
              promise, and you should not expect, that your Personal Information
              would always remain private.
            </p>
            <p>
              <strong>External Service Providers: </strong>There may be a number
              of services offered by external service providers that help you
              use our Site. If you choose to use these optional services, and in
              the course of doing so, disclose information to the external
              service providers, and/or grant them permission to collect
              information about you, in such case their use of your information
              is governed by their privacy policies.
            </p>
            <p>
              <strong>Other Corporate Entities: </strong>We share much of our
              information including your Personal Information, with our
              subsidiaries that are committed to serving your needs through use
              of our Site and related services. Such data shall be shared for
              the sole purpose of enhancing your experience of using the Site.
              To the extent that these entities have access to your information,
              they shall treat it at least as protectively as they treat
              information obtained from their other members. It is possible that
              we and/or subsidiaries, or any combination of such, could merge
              with or be acquired by another business entity. In any such
              combination we may share some or all of your information in order
              to continue to provide the service. You shall receive notice of
              such event (to the extent it occurs).
            </p>
            <p>
              <strong>Law and Order: </strong>We cooperate with law enforcement
              inquiries, as well as other third parties to enforce laws, such
              as: intellectual property rights, fraud and other rights. We can
              and you authorize us, to disclose your Personal Information to law
              enforcement and other government officials as we, in our sole
              discretion, believe necessary or appropriate, in connection with
              an investigation of fraud, intellectual property infringements, or
              other activity that is illegal or may expose us or you to any
              legal liability. Any information that you make publicly available
              on the Site may be potentially viewed by any party, and by posting
              such material it is deemed that you consent to share such
              information with such parties.
            </p>
            <p>
              <strong>DATA PROTECTION OFFICER</strong>
            </p>
            <p>
              The Company has appointed a Data Protection Officer to manage your
              Personal Information and to respond the certain requests. On
              matters of data protection this policy is to practice extreme
              caution for the benefit of both that is you and the Company. If
              you have any questions or concerns about our privacy practices
              with respect to your Personal Information, you can reach out to
              our Data Protection Officer at{" "}
              <a href="mailto:help@karishye.co">help@karishye.co.</a>
            </p>
            <p>
              <strong>AMENDMENT</strong>
            </p>
            <p>
              The Company may update or modify this Privacy Policy from time to
              time in order to comply with regulations and market standards. If
              you would like to access, correct, amend, remove or limit the use
              or disclosure of any Personal Information that has been collected
              and stored by Site, you shall notify the Company at the provided
              contact address listed in this policy.
            </p>
            <p>
              If we change material terms of this Policy, we will provide notice
              of the revised policy for thirty (30) days on the home page of the
              Site. We will also take other steps as necessary depending on the
              nature of the changes, including obtaining your consent where
              required by local data protection laws, or providing you with the
              opportunity to delete your Personal Information.
            </p>
            <p>
              <strong>
                CHILDREN’S ONLINE PRIVACY PROTECTION ACT COMPLIANCE (COPPA)
              </strong>
            </p>
            <p>
              We are in compliance with the requirements of COPPA, we do not
              collect any Personal Information knowingly from anyone under
              eighteen (18) years of age. Our Site, products and services are
              all directed to people who are at least eighteen (18) years old or
              older.
            </p>
            <p>
              <strong>CONSENT</strong>
            </p>
            <p>
              By using the Site or by providing Personal Information, you
              consent to collect and use of your Personal Information in
              accordance with this Privacy Policy, including but not limited to
              consent for sharing the information as per this Privacy Policy.
            </p>
            <p>
              <strong>CONTACT US</strong>
            </p>
            <p>
              For more information about our privacy practices, if you have
              queries, concerns or feedbacks or even if you have any concerns
              about our adherence to this Privacy Policy, please reach us at{" "}
              <a href="mailto:help@karishye.co">help@karishye.co.</a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
      <Whatsapp/>
    </>
  );
}
