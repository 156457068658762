import axiosInstance from "./AxiosInstance";
import {debounce } from "../Utils/Debounce";

export const BookingService = {
  getTransactions: debounce (async(bookingId) => {
    try {
      const response = await axiosInstance.get(
        `/booking-transaction?booking_id=${bookingId}`
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching bookings:", error);
      throw error;
    }
  },300),

  getBookingDetails: debounce(async(bookingId) => {
    try {
      const response = await axiosInstance.get(`/booking/${bookingId}`);
      return response.data.data;
    } catch (error) {
      console.error("Error fetching bookings:", error);
      throw error;
    }
  },300),

  getParticularBookingDetails: debounce(async(bookingId) => {
    try {
      const response = await axiosInstance.post(
        `/booking/puja-by-bookingId/${bookingId}`
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching bookings:", error);
      throw error;
    }
  },300),

  getAssignedBookings: debounce(async (userId) => {
    try {
      const response = await axiosInstance.get(
        `/booking/assigned-bookings?userId=${userId}`
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching bookings:", error);
      throw error;
    }
  },300),

  createBooking: debounce(async(userId, bookingData) => {
    console.log("bookingData", bookingData);
    console.log("iam booking the  puja")
    const response = await axiosInstance.post(
      `/booking/puja-booking?userId=${userId}`,
      bookingData // Send the data directly
    );
    console.log("response", response);
    return response;
  },300),
};
