import { Helmet } from "react-helmet";
import Header from "../../Component/Header";
import CategorySection from "./CategorySection";
import React, { useState, useEffect }  from "react";
import Footer from "../../Component/Footer/Footer";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import FaqSection from "./FaqSection";          //changed the sections names
import { Tabs} from "react-tabs";
import Loader from "../../Component/Loader/Loader";
import { logPageView } from "../../Utils/Analytics";
export default function OfferingsPage() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    logPageView();
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoading(false);
    };

    // Check if document is already loaded
    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);
    }

    // Cleanup
    return () => window.removeEventListener('load', handleLoad);
  }, []);
  return (
    <>
    {isLoading && <Loader fullScreen />}
      <Helmet>
        <title>
          Puja Offerings - Explore Rituals and Ceremonies for Well-being with
          Karishye
        </title>
        <meta
          name="description"
          content="Choose from a wide range of pujas and ceremonies tailored for well-being, life events, and spiritual growth. Experience the richness of Vedic traditions with Karishye."
        />
      </Helmet>
      <Tabs
        className="tw-w-full"
        selectedTabClassName="tw-tab--selected"
        selectedTabPanelClassName="!tw-relative tw-tab-panel--selected"
      >
        <div className="tw-flex tw-flex-col tw-gap-[50px] tw-bg-cover tw-bg-no-repeat tw-py-2.5 md:tw-h-auto sm:tw-gap-0 tw-bg-[url(/public/assets/images/background_image1.webp)]">
          <Header />

          <div className="container">
            <CategorySection />
          </div>      {/*removed the sections here and added as components*/}
         <FaqSection />
        </div>
      </Tabs>
      <Footer />
      <Whatsapp />
    </>
  );
}
